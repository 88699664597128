/*#region Breadcrumb */

.breadCrumbLayout {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.textBreadcrumb {
	font-size: 1.25rem;
}

.breadcrumbTextHome {
	color: rgba(30, 64, 175, 1);
}

.breadcrumbTextCurrent {
	width: 70px;
	font-family: inherit;
	font-size: 100%;
	margin-left: 0.5rem;
	padding: 0;
	border: 0;
}

.breadcrumbTextHome:hover {
	color: rgba(59, 130, 246, 1);
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.addColumnTextField {
	margin: 0.5rem;
}

.addColumnGroup {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

/*#endregion*/
