@value minWidthPhablet, minWidthTablet, minWidthDesktop from "../../constants/breakpoints.module.css";

/* Parent Page
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.AppPage {
	display: grid;
	align-items: flex-start;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	overflow-x: auto;
	padding-top: 0.75rem;
}

.ColumnGrid {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: stretch;
	align-content: normal;

	margin-left: 0.25rem;
	margin-right: 0.25rem;
	padding-top: 0.75rem;
}

/*#endregion*/
