.avatar_root_default {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	cursor: pointer;

	object-fit: cover;

	min-width: 10em;
	min-height: 10em;
}

.SvgIcon {
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	flex-shrink: 0;
	font-size: 1.5rem;
	color: #000000;
}

@media (min-width: 550px) {
	.avatar_root_default {
		display: block;
	}
}
