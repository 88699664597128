@value minWidthMobile, minWidthPhablet, minWidthTablet, minWidthDesktop from "../../constants/breakpoints.module.css";

/* Defaults (smaller than 400px) */
.flexParent {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 5px;

	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.flexChildLeft {
	order: 0;
}
.flexChildRight {
	order: 1;

	/* hack to make the buttons align to the right */
	display: grid;
}

svg {
	display: inline-block;
	vertical-align: top;
}

h1 {
	font-weight: 900;
	font-size: 20px;
	line-height: 1;
	margin: 6px 0 6px 10px;

	/* Compared to display: inline, the major difference is that display: inline-block allows to set a width and height on the element.
	Also, with display: inline-block, the top and bottom margins/paddings are respected, but with display: inline they are not.
	Compared to display: block, the major difference is that display: inline-block does not add a line-break after the element, 
	so the element can sit next to other elements. */
	display: inline-block;
	vertical-align: top;
}

/* Bigger than 400px */
@media minWidthMobile {
	.wrapper {
		padding: 15px;
	}

	/* once big enough we can add a margin between buttons
	"button + button" means select the first button
	placed immediately after the first button element */
	button + button {
		margin-left: 10px;
	}

	/* set the buttons to normal */
	.flexChildRight {
		display: block;
	}
}

/* Bigger than 550px */
@media minWidthPhablet {
}

/* Bigger than 750px */
@media minWidthTablet {
}

/* Bigger than 1000px */
@media minWidthDesktop {
}
