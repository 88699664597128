:root {
	--check-background: linear-gradient(
		135deg,
		hsl(192, 100%, 67%),
		hsl(280, 87%, 65%)
	);

	--dark-mode-circle-color: hsl(233, 14%, 35%);

	--TextPadding: 0;
	--TextMargin: 0;
}

.TaskContents {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);

	--tw-ring-offset-shadow: 0 0 transparent;
	--tw-ring-shadow: 0 0 transparent;

	display: block;
	background-color: white;

	margin-bottom: 1rem;

	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1rem;
	padding-bottom: 1rem;

	box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
		var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);

	transition-property: box-shadow;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.3s;

	cursor: grab;
}

.TaskContents:hover {
	--tw-ring-offset-shadow: 0 0 transparent;
	--tw-ring-shadow: 0 0 transparent;

	--tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);

	box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
		var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.TaskFooter {
	display: flex;

	/*#region Debug*/
	/* flex-direction: row-reverse; */
	justify-content: flex-end;
	border: var(--TextPadding) solid red;
	/*#endregion*/
}

.TaskTextInput {
	/*  this is the hidden text field that appears when clicked on */
	/* box-sizing: border-box; */
	display: block;
	color: rgba(29, 78, 216, 1);
	margin: var(--TextMargin);
	padding: var(--TextPadding);
	/* border: var(--TextPadding) solid green; */
}

.TaskText {
	cursor: text;
	/* box-sizing: border-box; */
	margin: var(--TextMargin);
	padding: var(--TextPadding);
	/* border: var(--TextPadding) solid green; */
}
