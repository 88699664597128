@value minWidthPhablet, minWidthTablet, minWidthDesktop from "../../../constants/breakpoints.module.css";

/* Parent Page
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

/*#region Column Content*/

.FlexItem {
	margin: 0.5rem;
	padding: 0.5rem;
	flex: none;
	width: 100%; /*percent of the containing block*/
}

/* Bigger than 550 */
@media minWidthPhablet {
	/* Columns become box grid instead of single column */
	.FlexItem {
		width: 30%; /* The browser calculates the width of the element based on its contents (shrinks it down to a little box) */
	}
}

/* Bigger than 750 */
@media minWidthTablet {
}

/* Bigger than 1000 */
@media minWidthDesktop {
}

.ColumnContainer {
	background: rgb(237, 242, 255);
}

.ColumnContents {
	display: block;

	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

/*#endregion*/

/*#region Column Header*/

.ColumnHeader {
	background-image: linear-gradient(90deg, #1d4ed8, #2563eb, #3b82f6);
	border-radius: 0.125rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.ColumnHeaderControls {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ColumnHeaderTextInput {
	/*  this is the hidden text field that appears when clicked on */
	box-sizing: border-box;
	border: 0 solid #e5e7eb;

	margin: 0;
	padding: 0;

	font-size: 1.5rem;
	line-height: 1.75rem;

	padding-left: 0.5rem;
	padding-right: 0.5rem;

	color: rgba(29, 78, 216, 1);
}

.ColumnHeaderText {
	cursor: text;

	box-sizing: border-box;
	border: 0 solid #e5e7eb;

	margin: 0;
	font-weight: inherit;
	font-size: 1.5rem;
	line-height: 1.75rem;

	color: rgba(219, 234, 254, 1);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/*#endregion*/
