@value minWidthMobile, minWidthPhablet, minWidthTablet, minWidthDesktop from "../../../constants/breakpoints.module.css";

/* Defaults (smaller than 400px) */

.flexContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;

	width: 100%; /*percent of the containing block*/
	min-height: 100%;
	margin: 0px auto;
	/* background-color: white; */
	/* animation: grow 5s infinite; */
}

.flexChild {
	/* size up to a full row when we are on small screens */
	width: 100%;
	min-height: 100px;
	margin: 0.4em;
	border-radius: 1em;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
	margin-bottom: 0.75rem;
	margin-right: 1rem;

	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.raise {
	box-shadow: 8px 8px 3px -1px rgba(0, 0, 0, 0.1);
}

.flexChildContents {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	height: 100%;

	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	/* when color transitions it will change to the new value over 2 seconds */
	transition: color 2s;
}

/* Bigger than 400px */
@media minWidthMobile {
	.flexChild {
		/* size down to our contents when we are on bigger screens */
		min-width: 10em;
		min-height: 10em;
		width: auto;
	}
}

.spinner {
	border: 8px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	border-top-color: white;
	opacity: 1;
	/* position: absolute; */
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	width: 16px;
	height: 16px;
	transition: opacity 250ms;
	animation: rotate-spinner 1s linear;
	animation-iteration-count: infinite;
}

@keyframes rotate-spinner {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes grow {
	0% {
		width: 100%;
	}
	50% {
		width: 40%;
	}
	100% {
		width: 100%;
	}
}

/* Bigger than 550px */
@media minWidthPhablet {
}

/* Bigger than 750px */
@media minWidthTablet {
}

/* Bigger than 1000px */
@media minWidthDesktop {
}
