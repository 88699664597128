/*#region Sections*/
.PagePadding {
	padding: 4rem;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerText {
	font-size: 1.875rem;
	line-height: 2.25rem;
}

/*#endregion*/

/*#region Spinner Content*/
.spinner {
	color: #5222d0;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 70px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	margin-top: 200px;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*#endregion*/

/*#region Board Boxes*/
.boardContent {
	margin-top: 2rem;
}

.boardContainer {
	display: flex;
	flex-wrap: wrap;
}

.boardTable {
	display: flex;
	flex-wrap: wrap;
}

.boardBox {
	width: 100%; /*percent of the containing block*/

	margin-bottom: 0.75rem;
	margin-right: 1rem;

	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.boardBoxContents {
	display: flex;
	align-items: center;

	justify-content: space-between;
}

/*#endregion*/

/*#region Suggestion*/
.SuggestionContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: baseline;
	gap: 5px;
	padding-top: 10rem;
	margin: 0rem;
}

.SuggestionElement {
	display: block;
}

.ShareBlock {
	display: flex;
}

.SvgIcon {
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	flex-shrink: 0;
	font-size: 1.5rem;
	color: #000000;
}

.BoardCard {
	display: block;
	width: 100%;

	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;

	overflow: hidden;

	justify-content: space-between;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

@media (min-width: 550px) {
	.BoardCard {
		width: auto;
		max-width: 200px;
	}
}

.UsernameHeader {
	display: inline;

	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 1.334;
	letter-spacing: 0em;
	margin-bottom: 0.35em;
	word-wrap: break-word;
}

@media (min-width: 550px) {
	.UsernameHeader {
		display: block;
		padding: 16px;
	}
}

.BoardCard_Actions {
	float: right;
	clear: right;
	display: flex;
	align-items: center;
	padding: 8px;
}

.CardButton:hover {
	text-decoration: none;
}
.CardButton {
	display: -ms-inline-flexbox;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	text-decoration: none;
	color: inherit;
	font-weight: 500;
	font-size: 0.8125rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	min-width: 64px;
	padding: 4px 5px;
	border-radius: 4px;

	color: #1d4ed8;
	font-weight: bolder;
}

.ripple {
	background-position: center;
	transition: background 0.8s;
}

.ripple:active {
	background-color: #6eb9f7;
	background-size: 100%;
	transition: background 0s;
}
/*#endregion*/

/* Bigger than 550 */
@media (min-width: 550px) {
	.PagePadding {
		padding: 8rem;
	}

	.headerText {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}

	/* Board Boxes become box grid instead of single column */
	.boardBox {
		width: auto; /* The browser calculates the width of the element based on its contents (shrinks it down to a little box) */
	}

	.SuggestionContainer {
		width: auto;
		gap: 10px;
	}
}

/* Bigger than 750 */
@media (min-width: 750px) {
}

/* Bigger than 1000 */
@media (min-width: 1000px) {
}
