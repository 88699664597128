@value minWidthPhablet, minWidthTablet, minWidthDesktop from "../../constants/breakpoints.module.css";

.fullWidth {
	width: 100%;
	box-sizing: border-box;
}

.container {
	position: relative;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;
}

.section {
	padding: 8rem 0 7rem;
	text-align: center;
}

/*#region Hero*/
.phones {
	position: relative;
}
.phone {
	position: relative;
	max-width: 80%;
	margin: 3rem auto -12rem;
}
.phone + .phone {
	display: none;
}
/*#endregion*/

/*#region Forms*/

.docsHeader {
	text-transform: uppercase;
	font-size: 1.4rem;
	letter-spacing: 0.2rem;
	font-weight: 600;
}
.docsSection {
	border-top: 1px solid #eee;
	background-color: #17a07e;
	color: #fff;
	margin-bottom: 0;
}

/*#endregion*/

/* Bigger than 550 */
@media minWidthPhablet {
	.section {
		padding: 12rem 0 11rem;
	}
	.container {
		width: 80%;
	}
	.halfScreen {
		width: 48%;
		float: left;
	}
	.hero {
		padding-bottom: 12rem;
		text-align: left;
		height: 165px;
	}

	.phone {
		position: absolute;
		top: -7rem;
		right: 3rem;
		max-height: 362px;
		z-index: 3;
	}
	.phone + .phone {
		top: -6rem;
		display: block;
		max-width: 73.8%;
		right: 0;
		z-index: 2;
		max-height: 338px;
	}
	.heroHeading {
		font-size: 2.4rem;
	}
	.docsSection {
		padding: 6rem 0;
	}
}

/* Bigger than 750 */
@media minWidthTablet {
	.section {
		padding: 14rem 0 15rem;
	}
	.hero {
		height: 190px;
	}
	.heroHeading {
		font-size: 2.6rem;
	}

	.hero {
		padding: 16rem 0 14rem;
	}
	.phone {
		top: -14rem;
		right: 5rem;
		max-height: 510px;
	}
	.phone + .phone {
		top: -12rem;
		max-height: 472px;
	}
	.categories {
		padding: 15rem 0 8rem;
	}
}

/* Bigger than 1000 */
@media minWidthDesktop {
	.section {
		padding: 20rem 0 19rem;
	}
	.hero {
		padding: 22rem 0;
	}
	.heroHeading {
		font-size: 3rem;
	}
	.phone {
		top: -16rem;
		max-height: 615px;
	}
	.phone + .phone {
		top: -14rem;
		max-height: 570px;
	}
}
